import { Box, ButtonBase, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import cn from "classnames";
import React, { useMemo } from "react";

import { useIsDarkMode } from "state/user/hooks";

const useStyles = makeStyles(({ palette }) => ({
  wrapper: {
    fontWeight: 700,
    borderRadius: 30,
    minWidth: "64px",
    textTransform: "none",
    padding: "1px",
    margin: "2px",
    background: palette.error.main,
    color: "white",
    display: "flex",
    cursor: "pointer",
  },
  wrapperPrimaryDisabled: {
    fontWeight: 700,
    borderRadius: 12,
    minWidth: "64px",
    textTransform: "none",
    padding: "1px",
    margin: "2px",
    background: `linear-gradient(121.21deg, ${palette.success.main} 7.78%, ${palette.success.dark} 118.78%);`,
    display: "flex",
    cursor: "default",
    opacity: 0.3,
  },
  wrapperSecondary: {
    fontWeight: 700,
    borderRadius: 12,
    minWidth: "64px",
    textTransform: "none",
    padding: "1px",
    margin: "2px",
    background: `linear-gradient(316.57deg, ${palette.error.main} 18.89%, ${palette.error.dark} 95.84%);`,
    display: "flex",
    cursor: "pointer",
  },
  wrapperSecondarydisabled: {
    fontWeight: 700,
    borderRadius: 12,
    minWidth: "64px",
    textTransform: "none",
    padding: "1px",
    margin: "2px",
    background: `linear-gradient(316.57deg, ${palette.error.main} 18.89%, ${palette.error.dark} 95.84%);`,
    display: "flex",
    cursor: "default",
    opacity: 0.3,
  },
  container: {
    borderRadius: 30,
    transition: "background 0.3s",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",

    "&:active": {
      opacity: 0.8,
    },
  },
  disabledCover: {
    height: "45px",
    width: "inherit",
    borderRadius: 12,
    backgroundColor: "white",
    opacity: 0.3,
    position: "absolute",
    zIndex: 10,
  },

  label: {
    color: "white",
    fontWeight: 700,
    whiteSpace: "nowrap",
    fontSize: ({ size }: any) => (size === "large" ? "16px" : "14px"),
    lineHeight: "18px",
  },
}));

interface ContainedButtonProps {
  id?: string;
  height?: string;
  color?: string;
  label?: React.ReactNode;
  children?: React.ReactNode;
  textStyling?: object;
  disabled?: boolean;
  startIcon?: any;
  endIcon?: any;
  fullWidth?: boolean;
  margin?: string;
  className?: string;
  size?: "small" | "medium" | "large";
  onClick?: () => void;
}

const ContainedButton: React.FC<ContainedButtonProps> = ({
  id,
  fullWidth,
  color,
  height,
  label,
  children,
  textStyling,
  disabled,
  startIcon,
  endIcon,
  margin,
  size,
  className,
  onClick,
}) => {
  const darkMode = useIsDarkMode();
  const classes = useStyles({ darkMode, size, disabled });
  const childLabel = useMemo(
    () => (children === undefined ? label : children),
    [children, label]
  );

  return (
    <Box
      clone
      id={id}
      width={fullWidth ? "100%" : "auto"}
      height={height ? height : "45px"}
      className={cn(
        className,
        !disabled
          ? color === "secondary"
            ? classes.wrapperSecondary
            : classes.wrapper
          : color === "secondary"
          ? classes.wrapperSecondarydisabled
          : classes.wrapperPrimaryDisabled
      )}
      style={{ margin }}
      onClick={!disabled ? onClick : () => {}}
    >
      <ButtonBase>
        <Box
          height={height ? height : "45px"}
          padding="6px 2.25rem"
          className={classes.container}
        >
          {startIcon && (
            <Box
              display="flex"
              alignItems="center"
              marginRight="2px"
              className="startIcon"
              style={{ backgroundColor: "transparent" }}
            >
              {startIcon}
            </Box>
          )}

          {childLabel && (
            <Typography className={classes.label} style={textStyling}>
              {childLabel}
            </Typography>
          )}

          {endIcon && (
            <Box
              display="flex"
              alignItems="center"
              marginLeft="8px"
              className="endIcon"
              style={{ backgroundColor: "transparent" }}
            >
              {endIcon}
            </Box>
          )}
        </Box>
      </ButtonBase>
    </Box>
  );
};

export default ContainedButton;
