import { getAddress } from "@ethersproject/address";
import BigNumber from "bignumber.js";
import { BigNumber as EthersBigNumber } from "@ethersproject/bignumber";
declare global {
  interface Window {
    ethereum: any;
  }
}

const DOMAIN_TYPE = [
  { type: "string", name: "name" },
  { type: "string", name: "version" },
  { type: "uint256", name: "chainId" },
  { type: "address", name: "verifyingContract" },
];

const TypeVersion = "1.0.0";

export function isAddress(value: any): string | false {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

export function shortenAddress(address?: string, chars = 4): string {
  const parsed = isAddress(address);

  if (!parsed) {
    if (address?.includes("69420") && address?.includes("1337h4x0r")) {
      return `${address.substring(0, chars + 2)}...${address.substring(
        42 - chars
      )}`;
    }

    return "";
  }

  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`;
}

export async function switchPolygonNetwork() {
  await window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [
      {
        chainId: "0x89",
      },
    ],
  });
}

export const getNetworkName = (chainID: any) => {
  switch (chainID) {
    case 1:
      return "ETH mainnet";
    case 3:
      return "ETH Ropsten testnet";
    case 4:
      return "ETH Rinkeby testnet";
    case 5:
      return "ETH Goerli testnet";
    case 42:
      return "ETH Kovan testnet";
    case 100:
      return "ETH XDai testnet";
    case 137:
      return "Polygon mainnet";
    case 80001:
      return "Mumbai testnet";
    default:
      return "Connect to Polygon";
  }
};

export const send = (provider: any, method: string, params?: any[]) =>
  new Promise<any>((resolve, reject) => {
    const payload = {
      id: 999999999999,
      method,
      params,
    };
    const callback = (err: any, result: any) => {
      if (err) {
        reject(err);
      } else if (result.error) {
        console.error(result.error);
        reject(result.error);
      } else {
        resolve(result.result);
      }
    };

    let _provider = provider.provider || provider;

    if (_provider.sendAsync) {
      _provider.sendAsync(payload, callback);
    } else {
      _provider.send(payload, callback);
    }
  });

export interface RSV {
  r: string;
  s: string;
  v: number;
}

export const signData = async (
  provider: any,
  fromAddress: string,
  typeData: any
): Promise<RSV> => {
  const typeDataString =
    typeof typeData === "string" ? typeData : JSON.stringify(typeData);
  const result = await send(provider, "eth_signTypedData_v4", [
    fromAddress,
    typeDataString,
  ]).catch((error: any) => {
    if (error.message === "Method eth_signTypedData_v4 not supported.") {
      return send(provider, "eth_signTypedData", [fromAddress, typeData]);
    } else {
      throw error;
    }
  });

  return {
    r: result.slice(0, 66),
    s: "0x" + result.slice(66, 130),
    v: parseInt("0x" + result.slice(130, 132), 16),
  };
};

export const createTypeData = (domainData: any, message: any) => {
  return {
    types: {
      EIP712Domain: DOMAIN_TYPE,
      TrainPermit: [
        { name: "timestamp", type: "uint256" },
        { name: "gen1TokenId", type: "uint256" },
      ],
    },
    domain: domainData,
    primaryType: "TrainPermit",
    message,
  };
};

export const sign = async (
  web3: any,
  gen1TokenId: any,
  timestamp: any,
  verifyingContract: any,
  account: string,
  chainId: any
) => {
  const data = createTypeData(
    {
      name: "TrainPermit",
      version: TypeVersion,
      chainId,
      verifyingContract,
    },
    { timestamp, gen1TokenId }
  );
  const returnData = await signData(web3, account, data);
  return returnData;
};

export const ethersToBigNumber = (ethersBn: EthersBigNumber): BigNumber =>
  new BigNumber(ethersBn.toString());

export const getBalanceAmount = (amount: BigNumber, decimals = 18) => {
  return new BigNumber(amount).dividedBy(new BigNumber(10).pow(decimals));
};

export const getDecimalAmount = (amount: BigNumber, decimals = 18) => {
  return new BigNumber(amount).times(new BigNumber(10).pow(decimals));
};
