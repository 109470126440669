import {
  Box,
  Grid, IconButton,
  Link, Tab, Tabs, Tooltip, Typography, useMediaQuery
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { CheckCircleOutline } from "@material-ui/icons";
import { ReactComponent as AddIcon } from "assets/svg/AddIcon.svg";
import { ReactComponent as DeleteIcon } from "assets/svg/Delete.svg";
import BigNumber from "bignumber.js";
import cn from "classnames";
import { ContainedButton } from "components";
import { ChainId } from "constants/tokens";
import {
  useDeathRobotContract, useFighter1Contract, useGCOINContract, useGen2TrainerContract, useMysteryContract, useWeaponContract
} from "hooks/useContract";
import useTransact from "hooks/useTransact";
import { compact, map } from "lodash";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { useWeb3 } from "state/application/hooks";
import { ethersToBigNumber, getBalanceAmount } from "utils";
import {
  getDeathRobotAddress,
  getFighter1Address,
  getGen2TrainerAddress
} from "utils/addressHelpers";
import call from "utils/call";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  mainWrapper: {
    paddingTop: 180,
    maxWidth: 1100,
    margin: "auto",
  },
  training: {
    paddingBottom: "3rem",
    "& #train": {
      width: 253,
      fontSize: 30,
      marginLeft: "50%",
      transform: "translateX(-50%)",
      background: "#00B9BA",
    },
  },
  box: {
    width: 180,
    height: 180,
    background: "rgba(35, 30, 94, 0.78)",
    borderRadius: 20,
    margin: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",

    "& button": {
      position: "absolute",
      bottom: 20,
      left: 20,

      "& path": {
        fill: "black",
      },
    },

    "& img": {
      width: 160,
      height: 160,
      borderRadius: 20,
      transition: "all 0.3s ease",

      "&:hover": {
        boxShadow:
          "0 0 70px rgb(48 138 255 / 80%), 0 5px 20px rgb(48 138 255 / 80%), inset 0 1px #a8ecff, inset 0 -1px #a8ecff",
      },
    },

    "& video": {
      width: 160,
      height: 160,
      borderRadius: 20,
      transition: "all 0.3s ease",

      "&:hover": {
        boxShadow:
          "0 0 70px rgb(48 138 255 / 80%), 0 5px 20px rgb(48 138 255 / 80%), inset 0 1px #a8ecff, inset 0 -1px #a8ecff",
      },
    },
  },
  gridView: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",

    "& $title": {
      top: -30,
    },

    [breakpoints.down("md")]: {
      marginTop: "3rem",
      marginBottom: "2rem",
    },
  },
  disabled: {
    opacity: 0.5,
  },
  firstView: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    position: "relative",

    "& $title": {
      top: -30,
    },

    [breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  lastView: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    position: "relative",

    "& $title": {
      top: -30,
    },

    [breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  paper: {
    justifyContent: "center",
    display: "flex",
    position: "relative",
    cursor: "pointer",

    "& img": {
      width: 135,
      height: 135,
      borderRadius: 20,
    },

    "& video": {
      width: 135,
      height: 135,
      borderRadius: 20,
    },
  },
  prevIcon: {
    transform: "rotate(180deg)",
  },
  carousel: {
    background: "#1B1B34",
    borderRadius: 20,
    padding: "15px 15px",
    marginTop: 10,
    height: 165,
  },
  balance: {
    color: "black",
    position: "absolute",
    bottom: "1rem",
    left: "calc(50% + 2rem)",
  },
  title: {
    color: "white",
    position: "absolute",
    fontFamily: 'Ribuk',
  },
  tabs: {
    width: 640,
    marginLeft: "50%",
    transform: "translateX(-50%)",
    marginTop: 30,

    "& *": {
      fontFamily: "Ribuk",
    },

    [breakpoints.down("sm")]: {
      width: 350,
    },
  },
  statement: {
    whiteSpace: "nowrap",
    lineHeight: "135px",
    textAlign: "center",
    fontFamily: "Ribuk",
    fontWeight: 600
  },
  delete: {
    position: "absolute",
    top: -4,
    right: -4,
    cursor: "pointer",
  },
  trainBtn: {
    background: "#00B9BA!important"
  }
}));

const Item: React.FC<any> = (props) => {
  const classes = useStyles();
  const supportedImgs = [31000, 31001, 31002, 31003, 31004, 31005, 31006, 32000, 32001, 32002, 32003, 32004, 32005, 32006, 41000]
  const getImageUrl = () => {
    if (parseInt(props.tokenId) / 10000 < 3) {
      return `https://gfcmetaimage.blob.core.windows.net/weapons/images/${props.tokenId}.png`
    } else {
      // if (supportedImgs.findIndex((img) => img == props.tokenId) >= 0) {
        return `https://gfcmetaimage.blob.core.windows.net/p2e/images/${props.tokenId}.png`
      // } else {
      //   return `https://gfcmetaimage.blob.core.windows.net/p2e/images/p2e_unknownWeapon.png`
      // }
    }
  }
  return props.type !== "mystery" ? (
    <Box className={classes.paper}>
      <img
        onClick={() => props.onClick(props.tokenId)}
        src={getImageUrl()}
        alt="item"
      />
      <Typography className={classes.balance}>x {props.balance}</Typography>
    </Box>
  ) : (
    <Box
      className={cn(classes.paper, props.disabled ? classes.disabled : null)}
    >
      <img
        onClick={() => props.onClick(props.tokenId)}
        src="https://gfcmetaimage.blob.core.windows.net/images/vial.png"
        alt="mystery-item"
      />
    </Box>
  );
};

const FighterItem: React.FC<any> = (props) => {
  const classes = useStyles();
  return props.disabled ? (
    <Tooltip title={props.title}>
      <Box className={cn(classes.paper, classes.disabled)}>
        <img
          onClick={() => props.onClick(props.tokenId)}
          src={
            props.type === "fighter"
              ? `https://gfcmetaimage.blob.core.windows.net/images/fighter/images/${props.tokenId}.png`
              : "https://gfcmetaimage.blob.core.windows.net/images/robot.png"
          }
          alt="fighter-item"
        />
      </Box>
    </Tooltip>
  ) : (
    <Box className={classes.paper}>
      <img
        onClick={() => props.onClick(props.tokenId)}
        src={
          props.type === "fighter"
            ? `https://gfcmetaimage.blob.core.windows.net/images/fighter/images/${props.tokenId}.png`
            : "https://gfcmetaimage.blob.core.windows.net/images/robot.png"
        }
        alt="fighter-item"
      />
    </Box>
  );
};

const Training: React.FC = () => {
  const classes = useStyles();
  const { chainId, account, isCompleted } = useWeb3();
  const { breakpoints } = useTheme();

  const [value, setValue] = React.useState(0);
  const [flag, setFlag] = useState(false);
  const [allowance, setAllowance] = useState<BigNumber>(new BigNumber(0));
  const [weaponTokenIds, setWeaponTokenIds] = useState<any[]>([]);
  const [p2eWeaponTokenIds, setP2eWeaponTokenIds] = useState<any[]>([]);
  const [fighterTokenIds, setFighterTokenIds] = useState<any[]>([]);
  const [robotTokenIds, setRobotTokenIds] = useState<any[]>([]);
  const [mysteryTokenIds, setMysteryTokenIds] = useState<any[]>([]);
  const [selectedFighterId, setSelectedFighterId] = useState<any>(null);
  const [selectedRobotId, setSelectedRobotId] = useState<any>(null);
  const [selectedMysteryId, setSelectedMysteryId] = useState<any>(null);
  const [selectedWeaponIds, setSelectedWeaponIds] = useState<number[]>([]);
  const [trainerCost, setTrainerCost] = useState<BigNumber | null>(null);
  const [balance, setBalance] = useState<BigNumber>(new BigNumber(0));
  const mobile = useMediaQuery(breakpoints.down("sm"));
  const tablet = useMediaQuery(breakpoints.down("md"));
  const weaponContract = useWeaponContract(false);
  const fighterContract = useFighter1Contract(false);
  const robotContract = useDeathRobotContract(false);
  const mysteryContract = useMysteryContract(false);
  const trainerContract = useGen2TrainerContract(true);
  const gcoinContract = useGCOINContract(true);
  const transact = useTransact();
  const invalidTokenIds = [34001,34002,35001,42002,42003,43001,43002,43003,
    43004,43004,43005,43006,43007,43008,43009,43010,
    44001,44002,44003,44004,45001,45002,45003,45004,
    45005,46001];

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const sliderSettings = {
    className: classes.carousel,
    infinite: false,
    centerPadding: "2rem",
    slidesToShow: mobile ? 2 : tablet ? 4 : 6,
    slidesToScroll: mobile ? 2 : tablet ? 4 : 6,
    draggable: false,
    dots: false,
    speed: 500,
    rows: 1,
    slidesPerRow: 1,
  };

  useEffect(() => {
    const fetchTrainerCost = async () => {
      const trainingCost = await trainerContract?.trainingCost();
      setTrainerCost(getBalanceAmount(ethersToBigNumber(trainingCost)));
    };

    if (account && trainerContract && (chainId === 137 || chainId === 80001)) {
      fetchTrainerCost();
    }

    const fetchBalance = async () => {
      let _balance = await gcoinContract?.balanceOf(account);
      _balance = getBalanceAmount(ethersToBigNumber(_balance));
      setBalance(_balance);
    };

    if (account && gcoinContract && (chainId === 137 || chainId === 80001)) {
      fetchBalance();
    }
  }, [account, chainId, isCompleted]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchRobotIds = async () => {
      try {
        const tokenIds = await robotContract?.tokensOfOwner(account);
        const signers = await Promise.all(
          tokenIds.map((id: any) =>
            call(
              `https://gfc-chain-verifier.azurewebsites.net/verify?publicAddress=${account}&tokenAddress=${getDeathRobotAddress(
                ChainId.MAINNET
              )}&tokenId=${ethersToBigNumber(id).toString()}`,
              "GET",
              {}
            )
          )
        );
        const availability = await Promise.all(
          signers.map(({ tokenId }) =>
            trainerContract?.gen1AvailableToTrain(tokenId)
          )
        );
        setRobotTokenIds(
          tokenIds.map((id: any, index: number) => {
            return {
              token_id: id.toNumber(),
              available: availability[index],
              balance: 1,
              type: "robot",
            };
          })
        );
      } catch (err) {
        console.log("error: ", err);
      }
    };

    const fetchTokenIds = async () => {
      try {
        const tokenIds = [
          11000, 11001, 11002, 12000, 12001, 13000, 13001, 14000, 14001, 14002,
          15000, 15001, 16000, 17000, 18000, 21000, 21001, 22000, 22001, 22002,
          22003, 23000, 23001, 23002, 23003, 23004, 23005, 23006, 23007, 23008,
          23009, 23010, 24000, 24001, 24002, 24003, 24004, 24005, 25000, 25001,
          25002, 25003, 25004, 25005, 26000, 26001, 27000, 28000,
        ];

        const balanceBNs = await Promise.all(
          tokenIds.map((tokenId) => {
            return weaponContract?.balanceOf(account, tokenId);
          })
        );
        const balances = map(balanceBNs, (a) => a.toNumber());
        const filteredTokenIds = compact(
          tokenIds.map((id, index) => {
            if (balances[index] > 0)
              return {
                balance: balances[index],
                token_id: id,
              };
            return null;
          })
        );
        setWeaponTokenIds(filteredTokenIds);
      } catch (err) {
        console.log("error:", err);
      }
    };

    const fetchPe2TokenIds = async () => {
      try {
        const tokenIds = [
          31000, 31001, 31002, 31003, 31004, 31005, 31006, 32000, 32001, 32002,
          32003, 32004, 32005, 32006, 33000, 34000, 35000, 36000, 37000, 38000,
          41000, 41001, 41002, 42000, 42001, 43000, 44000, 45000, 46000, 47000,
          48000,
        ];

        const balanceBNs = await Promise.all(
          tokenIds.map((tokenId) => {
            return weaponContract?.balanceOf(account, tokenId);
          })
        );
        const balances = map(balanceBNs, (a) => a.toNumber());
        const filteredTokenIds = compact(
          tokenIds.map((id, index) => {
            if (balances[index] > 0)
              return {
                balance: balances[index],
                token_id: id,
              };
            return null;
          })
        );
        setP2eWeaponTokenIds(filteredTokenIds);
      } catch (err) {
        console.log("error: ", err);
      }
    }

    const fetchFighterTokenIds = async () => {
      try {
        const tokenIds = await fighterContract?.tokensOfOwner(account);
        const signers = await Promise.all(
          tokenIds.map((id: any) =>
            call(
              `https://gfc-chain-verifier.azurewebsites.net/verify?publicAddress=${account}&tokenAddress=${getFighter1Address(
                ChainId.MAINNET
              )}&tokenId=${id}`,
              "GET",
              {}
            )
          )
        );
        const availability = await Promise.all(
          signers.map(({ tokenId }) =>
            trainerContract?.gen1AvailableToTrain(tokenId)
          )
        );
        setFighterTokenIds(
          tokenIds.map((id: any, index: number) => {
            return {
              token_id: id.toNumber(),
              available: availability[index],
              balance: 1,
              type: "fighter",
            };
          })
        );
      } catch (err) {
        console.log("error: ", err);
      }
    };

    const fetchMysteryTokenIds = async () => {
      try {
        const tokenIds = [2];
        const balanceBNs = await Promise.all(
          tokenIds.map((tokenId) => {
            return mysteryContract?.balanceOf(account, tokenId);
          })
        );
        const balances = map(balanceBNs, (a) => a.toNumber());
        const filteredTokenIds = compact(
          tokenIds.map((id, index) => {
            if (balances[index] > 0)
              return {
                balance: 1,
                token_id: id,
              };
            return null;
          })
        );
        setMysteryTokenIds(filteredTokenIds);
      } catch (err) {
        console.log("error: ", err);
      }
    };

    const fetchAllowance = async () => {
      const all = await gcoinContract?.allowance(
        account,
        getGen2TrainerAddress(ChainId.MATIC)
      );
      const _allowance = getBalanceAmount(ethersToBigNumber(all));

      setAllowance(_allowance);
    };

    fetchAllowance();

    if (account && weaponContract && (chainId === 137 || chainId === 80001)) {
      fetchTokenIds();
      fetchPe2TokenIds();
    }

    if (account && fighterContract && (chainId === 137 || chainId === 80001)) {
      fetchFighterTokenIds();
    }

    if (account && robotContract && (chainId === 137 || chainId === 80001)) {
      fetchRobotIds();
    }

    if (account && mysteryContract && (chainId === 137 || chainId === 80001)) {
      fetchMysteryTokenIds();
    }
  }, [account, chainId, flag]); // eslint-disable-line react-hooks/exhaustive-deps

  const onWeaponSelected = (tokenId: any) => {
    if (selectedWeaponIds.length < 4) {
      setSelectedWeaponIds([...selectedWeaponIds, tokenId]);
      let assignedObj: any[] = [];
      Object.assign(assignedObj, [...weaponTokenIds, ...p2eWeaponTokenIds], []);
      assignedObj.find((token) => token.token_id === tokenId).balance--;
      setWeaponTokenIds(assignedObj);
      console.log({selectedWeaponIds, weaponTokenIds})
    }
  };

  const removeIdFromPanel = (tokenId: any, index: number) => {
    let newWeaponIds: any[] = [];
    Object.assign(newWeaponIds, selectedWeaponIds, []);
    newWeaponIds.splice(index, 1);
    setSelectedWeaponIds(newWeaponIds);
    let assignedObj: any[] = [];
    Object.assign(assignedObj, [...weaponTokenIds, ...p2eWeaponTokenIds], []);
    assignedObj.find((token) => token.token_id === tokenId).balance++;
    setWeaponTokenIds(assignedObj);
  };

  const onFighterSelected = (tokenId: any) => {
    if (!selectedFighterId) {
      setSelectedFighterId(tokenId);
      let assignedObj: any[] = [];
      Object.assign(assignedObj, fighterTokenIds, []);
      assignedObj.find((token) => token.token_id === tokenId).balance--;
      setFighterTokenIds(assignedObj);
      if (selectedRobotId) removeRobotIdFromPanel(selectedRobotId);
    }
  };

  const onMysterySelected = (tokenId: any) => {
    if (!selectedMysteryId) {
      setSelectedMysteryId(tokenId);
      let assignedObj: any[] = [];
      Object.assign(assignedObj, mysteryTokenIds, []);
      assignedObj.find((token) => token.token_id === tokenId).balance--;
      setMysteryTokenIds(assignedObj);
    }
  };

  const onRobotSelected = (tokenId: any) => {
    if (!selectedRobotId) {
      setSelectedRobotId(tokenId);
      let assignedObj: any[] = [];
      Object.assign(assignedObj, robotTokenIds, []);
      assignedObj.find((token) => token.token_id === tokenId).balance--;
      setRobotTokenIds(assignedObj);
      if (selectedFighterId) removeFighterIdFromPanel(selectedFighterId);
    }
  };

  const removeRobotIdFromPanel = (tokenId: any) => {
    setSelectedRobotId(null);
    let assignedObj: any[] = [];
    Object.assign(assignedObj, robotTokenIds, []);
    assignedObj.find((token) => token.token_id === tokenId).balance++;
    setRobotTokenIds(assignedObj);
  };

  const removeFighterIdFromPanel = (tokenId: any) => {
    setSelectedFighterId(null);
    let assignedObj: any[] = [];
    Object.assign(assignedObj, fighterTokenIds, []);
    assignedObj.find((token) => token.token_id === tokenId).balance++;
    setFighterTokenIds(assignedObj);
  };

  const removeMysteryIdFromPanel = (tokenId: any) => {
    setSelectedMysteryId(null);
    let assignedObj: any[] = [];
    Object.assign(assignedObj, mysteryTokenIds, []);
    assignedObj.find((token) => token.token_id === tokenId).balance++;
    setMysteryTokenIds(assignedObj);
  };

  return chainId === 137 || chainId === 80001 ? (
    <Box className={classes.training}>
      <Grid container spacing={2} className={classes.mainWrapper}>
        <Grid item md={3} xs={12} className={classes.firstView}>
          {selectedFighterId !== null || selectedRobotId !== null ? (
            <Box className={classes.box}>
              <Typography className={classes.title}>Galaxy Fighter</Typography>

              {selectedFighterId && (
                <img
                  src={`https://gfcmetaimage.blob.core.windows.net/images/fighter/images/${selectedFighterId}.png`}
                  alt="item"
                />
              )}
              {selectedRobotId && (
                <img
                  src={`https://gfcmetaimage.blob.core.windows.net/images/robot.png`}
                  alt="item"
                />
              )}
              {selectedMysteryId && (
                <Tooltip title="Training with Vial">
                  <IconButton
                    onClick={() => removeMysteryIdFromPanel(selectedMysteryId)}
                  >
                    <CheckCircleOutline color="action" />
                  </IconButton>
                </Tooltip>
              )}
              <DeleteIcon
                className={classes.delete}
                onClick={() => {
                  selectedFighterId
                    ? removeFighterIdFromPanel(selectedFighterId)
                    : removeRobotIdFromPanel(selectedRobotId);
                }}
              />
            </Box>
          ) : (
            <Box className={classes.box}>
              <Typography className={classes.title}>Galaxy Fighter</Typography>

              <AddIcon />
            </Box>
          )}
        </Grid>
        <Grid className={classes.gridView} item md={6} xs={12}>
          <Typography className={classes.title}>Add 4 weapons</Typography>
          {selectedWeaponIds.map((id, index) => {
            return (
              <Box className={classes.box} key={`panel_item_${index}`}>
                <img
                  src={`https://gfcmetaimage.blob.core.windows.net/weapons/images/${id}.png`}
                  alt="item"
                />
                <DeleteIcon
                  className={classes.delete}
                  onClick={() => {
                    removeIdFromPanel(id, index);
                  }}
                />
              </Box>
            );
          })}
          {new Array(4 - selectedWeaponIds.length).fill(0).map((id, index) => {
            return (
              <Box className={classes.box} key={`empty_element_${index}`}>
                <AddIcon />
              </Box>
            );
          })}
        </Grid>
        <Grid item md={3} xs={12} className={classes.lastView}>
          {isCompleted ? (
            <Box className={classes.box}>
              <Typography className={classes.title}>Galaxy Fighter</Typography>

              <img
                src={`https://res.cloudinary.com/dpdgyzqro/image/upload/v1648490392/Prereveal_thipgi.gif`}
                alt="gen2 fighter"
              />
            </Box>
          ) : (
            <Box className={classes.box}>
              <Typography className={classes.title}>2nd Gen Fighter</Typography>
              <AddIcon />
            </Box>
          )}
        </Grid>
      </Grid>
      <ContainedButton
        id="train"
        label="Train"
        size="large"
        className={classes.trainBtn}
        fullWidth
        disabled={
          (selectedFighterId === null && selectedRobotId === null) ||
          selectedWeaponIds.length !== 4 ||
          trainerCost?.isGreaterThan(balance)
        }
        onClick={async () => {
          try {
            if (trainerCost?.isLessThanOrEqualTo(balance)) {
              const { signature, tokenId, deadline } = await call(
                `https://gfc-chain-verifier.azurewebsites.net/verify?publicAddress=${account}&tokenAddress=${
                  selectedFighterId
                    ? getFighter1Address(ChainId.MAINNET)
                    : getDeathRobotAddress(ChainId.MAINNET)
                }&tokenId=${selectedFighterId || selectedRobotId}`,
                "GET",
                {}
              );

              const r = signature.slice(0, 66);
              const s = "0x" + signature.slice(66, 130);
              const v = parseInt(signature.slice(130, 132), 16);

              console.log("tokenId:", tokenId);
              console.log("selectedWeaponIds:", selectedWeaponIds.toString());
              console.log("deadline:", deadline);
              console.log("vial:", selectedMysteryId !== null);
              console.log("v:", v);
              console.log("r: ", r);
              console.log("s:", s);

              if (allowance.isLessThan(new BigNumber(560))) {
                await transact(
                  gcoinContract?.approve(
                    getGen2TrainerAddress(ChainId.MATIC),
                    "100000000000000000000000"
                  ),
                  {
                    closeOnSuccess: true,
                    option: null,
                    description: "Approving trainer",
                  }
                );
              }
              await transact(
                trainerContract?.trainGFCGen2(
                  tokenId,
                  selectedWeaponIds,
                  deadline,
                  selectedMysteryId !== null,
                  v,
                  r,
                  s
                ),
                {
                  closeOnSuccess: true,
                  option: null,
                  description: "Train 2nd Gen Fighter",
                }
              );
              setFlag(!flag);
              setSelectedFighterId(null);
              setSelectedRobotId(null);
              setSelectedMysteryId(null);
              setSelectedWeaponIds([]);
            }
          } catch (err) {
            console.log("error: ", err);
          }
        }}
      />
      <Tabs
        className={classes.tabs}
        value={value}
        onChange={handleChange}
        aria-label="tabs"
      >
        <Tab label="Fighter" />
        <Tab label="Weapon" />
        <Tab label="P2E Weapon" />
        <Tab label="Vial" />
      </Tabs>
      {value === 0 && (
        <Slider {...sliderSettings}>
          {!![...fighterTokenIds, ...robotTokenIds].length ? (
            [...fighterTokenIds, ...robotTokenIds].map((item, index) => {
              return (
                <FighterItem
                  tokenId={item.token_id}
                  balance={item.balance}
                  key={`fighter_${index}`}
                  disabled={!item.available || item.balance === 0}
                  title={
                    item.balance === 0 ? "" : "This fighter is on cooldown"
                  }
                  type={item.type}
                  onClick={
                    item.balance !== 0 && item.available
                      ? item.type === "fighter"
                        ? onFighterSelected
                        : onRobotSelected
                      : item.type === "fighter"
                      ? () => removeFighterIdFromPanel(item.token_id)
                      : () => removeRobotIdFromPanel(item.token_id)
                  }
                />
              );
            })
          ) : (
            <Typography className={classes.statement}>
              You don't have any fighters&nbsp;&nbsp;&nbsp;
              <Link
                href="https://opensea.io/collection/galaxy-fight-club"
                target="_blank"
                rel="noreferrer"
              >
                Buy on OpenSea
              </Link>
            </Typography>
          )}
        </Slider>
      )}
      {value === 1 && (
        <Slider {...sliderSettings}>
          {!!weaponTokenIds.length ? (
            weaponTokenIds.map((item, index) => {
              if (item.balance > 0) {
                return (
                  <Item
                    tokenId={item.token_id}
                    balance={item.balance}
                    onClick={onWeaponSelected}
                    key={`weapon_${index}`}
                  />
                );
              } else {
                return null;
              }
            })
          ) : (
            <Typography className={classes.statement}>
              You don't have any genesis weapons&nbsp;&nbsp;&nbsp;
              <Link
                href="https://opensea.io/collection/gfc-genesis-weapon"
                target="_blank"
                rel="noreferrer"
              >
                Buy on OpenSea
              </Link>
            </Typography>
          )}
        </Slider>
      )}
      {value === 2 && (
        <Slider {...sliderSettings}>
          {!!p2eWeaponTokenIds.length ? (
            p2eWeaponTokenIds.filter((item) => !invalidTokenIds.find((id) => id == item.token_id)).map((item, index) => {
              if (item.balance > 0) {
                return (
                  <Item
                    tokenId={item.token_id}
                    balance={item.balance}
                    onClick={onWeaponSelected}
                    key={`weapon_${index}`}
                  />
                );
              } else {
                return null;
              }
            })
          ) : (
            <Typography className={classes.statement}>
              You don't have any p2e weapons&nbsp;&nbsp;&nbsp;
              <Link
                href="https://opensea.io/collection/gfc-genesis-weapon"
                target="_blank"
                rel="noreferrer"
              >
                Buy on OpenSea
              </Link>
            </Typography>
          )}
        </Slider>
      )}

      {value === 3 && (
        <Slider {...sliderSettings}>
          {!!mysteryTokenIds.length ? (
            mysteryTokenIds.map((item, index) => {
              return (
                <Item
                  tokenId={item.token_id}
                  balance={item.balance}
                  type="mystery"
                  disabled={item.balance === 0}
                  onClick={
                    item.balance !== 0
                      ? onMysterySelected
                      : () => removeMysteryIdFromPanel(item.token_id)
                  }
                  key={`mystery_${index}`}
                />
              );
            })
          ) : (
            <Typography className={classes.statement}>
              You don't have any vial items&nbsp;&nbsp;&nbsp;
              <Link
                href="https://opensea.io/assets/matic/0xfd24d200c6715f3c0a2ddf8a8b128952efed7724/2"
                target="_blank"
                rel="noreferrer"
              >
                Buy on OpenSea
              </Link>
            </Typography>
          )}
        </Slider>
      )}
    </Box>
  ) : (
    <></>
  );
};

export default Training;
