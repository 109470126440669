import React from "react";
import { Box, Grid, Divider, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { useIsDarkMode } from "state/user/hooks";

import { ReactComponent as TwitterIcon } from "assets/svg/TwitterIcon.svg";
import { ReactComponent as OpenseaIcon } from "assets/svg/OpenseaIcon.svg";
import { ReactComponent as DiscordIcon } from "assets/svg/DiscordIcon.svg";
import { ReactComponent as GithubIcon } from "assets/svg/GithubIcon.svg";

declare global {
  interface Window {
    logBadgeClick: () => void;
  }
}

const useStyles = makeStyles(({ palette }) => ({
  footer: {
    height: "100%",
    justifyContent: "flex-end",
    paddingRight: 16,
    paddingTop: 4,
    paddingBottom: 4
  },
  
  pl: {
    paddingLeft: 16,
  },

  footerIcon: {
    cursor: "pointer",
    marginLeft: 16,
    maxWidth: 16,
    maxHeight: 16,
    display: "flex",
    "&:hover path": {
      fill: palette.text.primary,
    },
    "& path": {
      fill: palette.text.secondary,
    },
  },

  footerRight: {
    display: "flex",
    alignItems: "center",
    marginRight: (props: any) => (props.mobile ? 0 : 12.5),
    justifyContent: (props: any) =>
      props.mobile ? "space-between" : "flex-end",
    height: (props: any) => (props.mobile ? "50px" : "40px"),
    order: (props: any) => (props.mobile ? 0 : 1),
    width: (props: any) => (props.mobile ? "100%" : "auto"),

    "& hr": {
      height: 25,
    },
  },

  footerRightItem: {
    padding: (props: any) =>
      props.mobile ? "0 10px 0 24px" : "0px 5px 0 16.5px",
    display: "flex",
    alignItems: "center",
    color: palette.text.secondary,
    cursor: "pointer",
    width: (props: any) => (props.mobile ? "50%" : "auto"),
    "&:first-child": {
      "& svg": {
        width: 13.25,
        height: 21.58,
      },
    },
    "&:last-child": {
      justifyContent: (props: any) => props.mobile && "flex-end",
    },
    "& span": {
      fontSize: 14,
    },
    "& svg": {
      marginRight: 8,

      "& path": {
        fill: palette.text.secondary,
      },
    },
  },

  upArrow: {
    margin: "0 7px !important",
    height: "6.7px !important",
    width: "10.68px !important",
    "& path": {
      fill: "none !important",
      stroke: palette.text.secondary,
    },
  },

  gasProgress: {
    width: 35,
    background: "rgba(141, 151, 160, 0.4)",
  },

  footerDivider: {
    width: "100%",
  },

  subheading: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: 1.12,
    color: palette.text.primary,
  },

  text: {
    fontSize: 14,
    lineHeight: "16px",
    color: palette.text.secondary,
  },

  gasPriceText: {
    fontSize: 14,
    lineHeight: 1,
    textAlign: "left",
  },

  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: palette.text.secondary,
    cursor: "pointer",
    padding: "2px",
    "& svg": {
      marginRight: 8,
    },
    "& svg path": {
      fill: palette.secondary.main,
    },
    "& .MuiTypography-root": {
      width: "62px",
      fontWeight: 400,
      lineHeight: "14px",
      fontSize: "14px",
      color: palette.secondary.main,
      "& b": {
        fontWeight: 400,
      },
    },
    "&:hover": {
      "& svg path": {
        fill: palette.text.primary,
      },
      "& .MuiTypography-root": {
        fontWeight: 400,
        fontSize: "14px",
        color: palette.text.primary,
      },
    },
  },

  activeButton: {
    cursor: "default",
    "& svg path": {
      fill: palette.primary.main,
    },
    "& .MuiTypography-root": {
      color: palette.primary.main,
      "& b": {
        fontWeight: 700,
      },
    },
    "&:hover": {
      "& svg path": {
        fill: palette.primary.main,
      },
      "& .MuiTypography-root": {
        color: palette.primary.main,
      },
    },
  },

  gasPricePopup: {
    "& .MuiPopover-paper": {
      maxWidth: 368,
      "&::before": {
        content: '""',
        position: "absolute",
        marginRight: "-0.71em",
        bottom: 0,
        right: 40,
        width: 16,
        height: 16,
        background: palette.background.paper,
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.0746353)",
        transform: "translate(-50%, 50%) rotate(135deg)",
        clipPath:
          "polygon(-8px -8px, calc(100% + 8px) -8px, calc(100% + 8px) calc(100% + 8px))",
      },
    },
  },
}));

const Footer: React.FC = () => {
  const { palette, breakpoints } = useTheme();
  const dark = useIsDarkMode();
  const mobile = useMediaQuery(breakpoints.down("xs"));
  const classes = useStyles({ dark, mobile });

  return (
    <Box
      height={mobile ? 50 : 44}
      width={1}
      borderTop={1}
      borderColor={palette.divider}
      style={mobile ? { backgroundColor: palette.background.paper } : {}}
    >
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        className={classes.footer}
      >
        <Box
          display="flex"
          width={mobile ? "100%" : "auto"}
          height={mobile ? "48px" : "41px"}
          justifyContent={mobile ? "center" : "flex-start"}
          alignItems="center"
          style={{ order: mobile ? 1 : 0 }}
        >
          <a
            href="https://twitter.com/GalaxyFight_NFT"
            target="_blank"
            rel="noreferrer"
            className={classes.footerIcon}
          >
            <TwitterIcon />
          </a>
          <a
            href="https://opensea.io/category/galaxyfightclub"
            target="_blank"
            rel="noreferrer"
            className={classes.footerIcon}
          >
            <OpenseaIcon />
          </a>
          <a
            href="https://discord.com/invite/tbhXh5bYRG"
            target="_blank"
            rel="noreferrer"
            className={classes.footerIcon}
          >
            <DiscordIcon />
          </a>

          <a href="#" className={classes.pl}>
            <img
              onClick={() => window.logBadgeClick()} // eslint-disable-line
              id="badge-button"
              style={{ width: 155, height: 32 }}
              src="https://static.alchemyapi.io/images/marketing/badge.png"
              alt="Alchemy Supercharged"
            />
          </a>
          <a className={classes.pl} href="https://chn.lk/3C1ffBV" > <img src="https://chain.link/badge-randomness-black" style={{ width: 118, height: 32 }} alt="randomness secured with chainlink" /></a>
        </Box>
        {mobile && <Divider className={classes.footerDivider} />}
      </Grid>
    </Box>
  );
};

export default Footer;
