import { ChainId } from "@uniswap/sdk";

export function getTxLink(
  txHash: string,
  chainId: ChainId | 137 | 80001 | undefined
): string {
  switch (chainId) {
    case 80001:
      return `https://mumbai.polygonscan.com/tx/${txHash}`;

    case 137:
      return `https://polygonscan.com/tx/${txHash}`;

    default:
      return `https://etherscan.io/tx/${txHash}`;
  }
}
