import { get } from "lodash";
import { ContractAddresses } from "../constants/tokens";

export const getAddress = (label: string, chainId: any): string => {
  return get(ContractAddresses, `${chainId}.${label}`, "");
};

export const getFighter1Address = (chainId: any) => {
  return getAddress("Fighter1", chainId);
};

export const getDeathRobotAddress = (chainId: any) => {
  return getAddress("DeathRobot", chainId);
};

export const getWeaponAddress = (chainId: any) => {
  return getAddress("Weapon", chainId);
};

export const getGen2NFTAddress = (chainId: any) => {
  return getAddress("Gen2NFT", chainId);
};

export const getGen2TrainerAddress = (chainId: any) => {
  return getAddress("Gen2Trainer", chainId);
};

export const getMysteryAddress = (chainId: any) => {
  return getAddress("Mystery", chainId);
};

export const getGCOINAddress = (chainId: any) => {
  return getAddress("GCOIN", chainId);
};
