import {
  Box, Divider, Grid,
  IconButton, Tooltip, Typography
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { SupervisorAccount } from "@material-ui/icons";
import BigNumber from "bignumber.js";
import React, { useEffect, useState } from "react";

import { ReactComponent as FullLogo } from "assets/svg/FullLogo.svg";
import { ReactComponent as LogoutIcon } from "assets/svg/LogoutIcon.svg";
import { ContainedButton } from "components";
import { useGCOINContract, useGen2TrainerContract } from "hooks/useContract";
import { useDisconnect, useWeb3 } from "state/application/hooks";
import { useDarkModeManager } from "state/user/hooks";
import {
  ethersToBigNumber,
  getBalanceAmount,
  getNetworkName,
  shortenAddress
} from "utils";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  page: {
    backgroundColor: "transparent",
    width: "calc(100% - 127px)",
    minHeight: "100vh",
  },

  divider: {
    height: "48px",
  },

  account: {
    padding: 0,
    height: 45,
    border: `1px solid ${palette.divider}`,
    backgroundColor: palette.success.main,
    borderRadius: 30,
    cursor: "pointer",
    color: palette.text.primary,

    "& a": {
      textDecoration: "none",
    },

    "&> div:hover:not(:active)": {
      "&> svg path": {
        fill: palette.text.primary,
      },

      "& p": {
        color: palette.text.primary,
      },
    },
  },

  accountInfo: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 12,
    paddingRight: 6,
  },

  accountMobile: {
    height: 45,
    width: "100%",
    backgroundColor: palette.success.main,
    border: `1px solid ${palette.divider}`,
    borderRadius: 30,
    color: palette.text.primary,

    "& $disconnect": {
      width: 43,
      flex: "none",
    },

    "& a": {
      textDecoration: "none",
    },

    "&> div:hover:not(:active)": {
      "&> svg path": {
        fill: palette.text.primary,
      },

      "& p": {
        color: palette.text.primary,
      },
    },
  },

  connect: {
    padding: "0 12px",
    height: 45,
    border: `1px solid ${palette.divider}`,
    borderRadius: 30,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },

  disconnect: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 10px 0 12px",

    "& svg path": {
      fill: palette.text.primary,
    },

    "&:hover:not(:active)": {
      "& svg path": {
        fill: palette.text.primary,
      },
    },

    "& button": {
      padding: 0,

      "& svg path": {
        fill: palette.text.primary,
      },

      ":active": {
        borderColor: palette.primary.main,

        "& svg path": {
          fill: palette.text.primary,
        },

        "& p": {
          color: palette.text.primary,
        },
      },
    },
  },

  address: {
    color: palette.text.primary,
    fontSize: 14,
  },

  addressMobile: {
    color: palette.text.primary,
    fontSize: 14,
    marginLeft: 12,
  },

  button: {
    margin: "0 10px 0 0",
    height: 45,
    "& svg": {
      marginLeft: 8,
    },
  },

  fullWidth: {
    margin: 0,
    width: "100%",
  },

  connectWalletButton: {
    fontSize: 14,
  },
  walletConnect: {
    display: "flex",
    background: palette.background.paper,
    borderRadius: 30,
    whiteSpace: "nowrap",
    alignItems: "center",
    padding: "8px 11px 8px 20px",
    
    "& p": {
      fontFamily: "Ribuk",
      fontWeight: 800,
      marginRight: 28,
    },
  },
  dot: {
    backgroundColor: (props: any) =>
      props.isWalletConnected ? palette.success.main : palette.error.main,
    minWidth: 10,
    height: 10,
    borderRadius: "50%",
    marginRight: 12,
  },
  title: {
    fontSize: 30,
    lineHeight: "37px",
    fontFamily: 'Molot'
  },
  subTitle: {
    fontSize: 20,
    fontWeight: 400,
    fontFamily: 'Ribuk'
  },
}));

interface AccountButtonsProps {
  mobile?: boolean;
  onHide?: () => void;
}

const AccountButtons: React.FC<AccountButtonsProps> = ({ mobile }) => {
  const { account, wallet, onboard, chainId, isCompleted } = useWeb3();
  const disconnect = useDisconnect();
  const isWalletConnected = !!wallet && !!wallet.provider && !!account;
  const [trainerCost, setTrainerCost] = useState<BigNumber | null>(null);
  const [balance, setBalance] = useState<BigNumber>(new BigNumber(0));
  const theme = useTheme();
  const [darkMode] = useDarkModeManager();
  const classes = useStyles({ darkMode, mobile, isWalletConnected });
  const trainerContract = useGen2TrainerContract(true);
  const gcoinContract = useGCOINContract(false);

  useEffect(() => {
    const fetchTrainerCost = async () => {
      const trainingCost = await trainerContract?.trainingCost();
      setTrainerCost(ethersToBigNumber(trainingCost));
    };

    if (account && trainerContract && (chainId === 137 || chainId === 80001)) {
      fetchTrainerCost();
    }

    const fetchBalance = async () => {
      let _balance = await gcoinContract?.balanceOf(account);
      _balance = getBalanceAmount(ethersToBigNumber(_balance));
      setBalance(_balance);
    };

    if (account && gcoinContract && (chainId === 137 || chainId === 80001)) {
      fetchBalance();
    }
  }, [account, chainId, isCompleted]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid
      container
      alignItems="center"
      justifyContent={!mobile ? "space-between" : "center"}
      id="here"
    >
      {mobile && <Divider className={classes.fullWidth} />}

      {wallet && wallet.provider && wallet.type === "hardware" && (
        <Grid item xs={2}>
          <Tooltip title="Switch Account">
            <IconButton onClick={onboard?.accountSelect}>
              <SupervisorAccount color="action" />
            </IconButton>
          </Tooltip>
        </Grid>
      )}

      {!mobile && !isWalletConnected && <FullLogo />}
      {!mobile && !isWalletConnected && (
        <Typography className={classes.title}>Gen 2 Training</Typography>
      )}

      {isWalletConnected ? (
        <>
          {!mobile && (
            <Box>
              <Typography className={classes.title}>
                Training (2nd Gen Fighters)
              </Typography>
              <Typography variant="h6" className={classes.subTitle}>
                1 fighter + 4 weapons (burned) +{" "}
                {trainerCost ? getBalanceAmount(trainerCost).toString() : ""}{" "}
                $GCOIN = 2nd generation fighter
              </Typography>
            </Box>
          )}
          <Box
            className={classes.walletConnect}
            justifySelf="center"
            justifyContent="center"
          >
            <Box className={classes.dot} />
            <Typography>
              {getNetworkName(chainId)} ({balance.toFixed(2).toString()} $GCOIN)
            </Typography>
            <Box
              display="flex"
              width={mobile ? 1 : "auto"}
              style={{ backgroundColor: "transparent" }}
            >
              {!mobile ? (
                <>
                  <Box clone mb={mobile ? 1 : 0}>
                    <Box display="flex" id="test">
                      <Grid container className={classes.account}>
                        <Box height={1} className={classes.accountInfo}>
                          <Box>
                            <Typography
                              className={
                                !mobile
                                  ? classes.address
                                  : classes.addressMobile
                              }
                            >
                              {shortenAddress(account ?? "")}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          height={1}
                          borderLeft={1}
                          borderColor={theme.palette.divider}
                          className={classes.disconnect}
                          onClick={disconnect}
                        >
                          <Tooltip title="Disconnect">
                            <LogoutIcon color="action" />
                          </Tooltip>
                        </Box>
                      </Grid>
                    </Box>
                  </Box>
                </>
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  width="100%"
                  pt={1.25}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    paddingX={"10px"}
                  >
                    <Box clone mb={mobile ? 1 : 0}>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        className={classes.accountMobile}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          flex={1}
                          height={1}
                        >
                          <Typography className={classes.addressMobile}>
                            {shortenAddress(account ?? "", 1)}
                          </Typography>
                        </Box>

                        <Box
                          height={1}
                          borderLeft={1}
                          borderColor={theme.palette.divider}
                          className={classes.disconnect}
                        >
                          <Tooltip title="Disconnect">
                            <IconButton onClick={disconnect}>
                              <LogoutIcon color="action" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </>
      ) : (
        <Box
          className={classes.walletConnect}
          justifySelf="center"
          justifyContent="center"
        >
          <Box className={classes.dot} />
          <Typography>Not connected</Typography>
          <ContainedButton
            id="wrappper"
            label="Connect"
            size="large"
            fullWidth
            textStyling={{ margin: "3px" }}
            onClick={() => {
              onboard?.walletSelect();
            }}
          />
        </Box>
      )}
    </Grid>
  );
};

export default AccountButtons;
