import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Onboard from "bnc-onboard";
import { ChainId } from "@uniswap/sdk";
import { Wallet } from "bnc-onboard/dist/src/interfaces";
import { ethers } from "ethers";

import { wallets } from "../../constants";
import { useIsDarkMode } from "state/user/hooks";
import { getSigner } from "web3/contracts";
import { AppState } from "state";
import { setWeb3Settings } from "./actions";

export default function Updater(): null {
  const dispatch = useDispatch();
  const location = useLocation();
  const dark = useIsDarkMode();

  const {
    onboard: _onboard,
    chainId,
    web3,
    signer,
    isEntered,
  } = useSelector<AppState, AppState["application"]>(
    (state) => state.application
  );

  const [state, setState] = useState<{
    hasRequestedAccounts: boolean;
  }>({
    hasRequestedAccounts: false,
  });

  useEffect(() => {
    if (!web3 || state.hasRequestedAccounts) return;

    setState((s) => ({ ...s, hasRequestedAccounts: true }));

    const ethereum = (window as any).ethereum;

    const handleGetAccountAndContracts = async (
      web3: ethers.providers.Web3Provider
    ) => {
      const { signer, chainId } = await getSigner(web3);

      dispatch(setWeb3Settings({ signer, chainId }));
    };

    ethereum?.request({ method: "eth_requestAccounts" }).then(() => {
      handleGetAccountAndContracts(web3).catch((e) => console.error(e));

      dispatch(setWeb3Settings({ ethereum }));
    });

    ethereum?.on("accountsChanged", async () => {
      handleGetAccountAndContracts(web3).catch((e) => console.error(e));
    });

    ethereum?.on("chainChanged", () => {
      document.location.reload();
    });
  }, [web3, location, signer, state.hasRequestedAccounts, dispatch]);

  useEffect(() => {
    if (_onboard) return;

    const onboard = Onboard({
      subscriptions: {
        address: (account: string) => dispatch(setWeb3Settings({ account })),
        network: (chainId: ChainId | 56 | 137) => {
          dispatch(setWeb3Settings({ chainId }));

          localStorage.setItem("chainId", String(chainId));
        },
        wallet: async (wallet: Wallet) => {
          const walletAvailable = await onboard.walletCheck();

          if (walletAvailable) {
            const web3 = new ethers.providers.Web3Provider(wallet.provider);

            if (window.localStorage) {
              window.localStorage.setItem("selectedWallet", wallet.name as any);
            }

            dispatch(setWeb3Settings({ wallet, web3 }));
          } else {
            dispatch(
              setWeb3Settings({
                wallet: null,
                web3: null,
                account: "",
              })
            );
          }
        },
      },
      networkId: 137,
      dappId: process.env.REACT_APP_BLOCKNATIVE_KEY,
      hideBranding: true,
      darkMode: dark,
      walletSelect: { wallets: wallets() },
      walletCheck: [
        { checkName: "derivationPath" },
        { checkName: "connect" },
        { checkName: "accounts" },
      ],
    });

    dispatch(setWeb3Settings({ onboard }));
  }, [dispatch, _onboard, location, chainId, signer, dark]);

  useEffect(() => {
    const previouslySelectedWallet = window.localStorage
      ? window.localStorage.getItem("selectedWallet")
      : undefined;

    if (
      previouslySelectedWallet &&
      _onboard &&
      isEntered &&
      !["WalletLink"].includes(previouslySelectedWallet)
    ) {
      _onboard.walletSelect(previouslySelectedWallet);
    }
  }, [_onboard, dispatch, web3, isEntered]);

  return null;
}
