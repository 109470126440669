import { useEffect } from "react";
import { ethers } from "ethers";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, AppState } from "state";
import { setGasPrices, setTxHistory } from "./actions";
import { GasNowData, Transaction } from "./reducer";
import { ChainId } from "constants/tokens";

export default function Updater(): null {
  const dispatch = useDispatch<AppDispatch>();

  let ws: any, websocket: any, timer: any;

  function convertGasNowValueToNumber(gasNowValue: number): number {
    return Math.floor((gasNowValue || 1) / Math.pow(10, 9));
  }

  const { account, chainId } = useSelector<AppState, AppState["application"]>(
    (state) => state.application
  );

  const fetchGasData = () => {
    clearTimeout(timer);

    if (chainId === ChainId.MATIC)
      fetch("https://gasstation-mainnet.matic.network", { method: "GET" })
        .then((res) => res.json())
        .then((json) => {
          console.log("json", json);

          const gasNowData: GasNowData = {
            slow: json.safeLow,
            standard: json.safeLow,
            fast: json.standard,
            rapid: json.fast,
            timestamp: json.blockTime,
          };

          dispatch(setGasPrices(gasNowData));
        });
  };

  const createWebSocketConnection = () => {
    if (ws) {
      return;
    }

    if ("WebSocket" in window) {
      websocket = false;

      ws = new WebSocket("wss://www.gasnow.org/ws/gasprice");

      ws.onmessage = function (event: any) {
        const dataStr = event.data;
        const json = JSON.parse(dataStr);
        const gasNowData: GasNowData = {
          slow: convertGasNowValueToNumber(json.data.slow),
          standard: convertGasNowValueToNumber(json.data.standard),
          fast: convertGasNowValueToNumber(json.data.fast),
          rapid: convertGasNowValueToNumber(json.data.rapid),
          timestamp: json.data.timestamp,
        };

        dispatch(setGasPrices(gasNowData));
      };

      ws.onclose = function () {
        ws = undefined;
        getGas(websocket);
      };
    } else {
      getGas(false);
    }
  };

  const getGas = (type: any) => {
    type ? createWebSocketConnection() : fetchGasData();
  };

  useEffect(() => {
    getGas(false);

    return () => {
      if (!ws) return;
      ws.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let provider = new ethers.providers.EtherscanProvider();

    async function fetchTransactions() {
      try {
        if (!!account) {
          const history = await provider.getHistory(account);
          const transactions: Transaction[] = history.map((item) => {
            return {
              hash: item.hash,
              timestamp: item.timestamp,
              complete: true,
            } as Transaction;
          });
          dispatch(setTxHistory(transactions));
        }
      } catch (err) {}
    }

    fetchTransactions();
  }, [account, dispatch]);

  useEffect(() => {
    fetchGasData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId]);

  return null;
}
