import React, { Suspense, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { Provider as StateProvider } from "react-redux";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import {
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
  Box,
} from "@material-ui/core";

import "animate.css/animate.css";
import { useIsDarkMode } from "state/user/hooks";
import { darkTheme, lightTheme } from "./theme";
import store from "./state";
import { PageWithSidebar } from "layouts";
import { useCloseModals, useModalOpen } from "state/application/hooks";
import { Training } from "./pages";
import ApplicationUpdater from "./state/application/updater";
import TransactionsUpdater from "./state/transactions/updater";
import UserUpdater from "./state/user/updater";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  TransactionLoadingModal,
  TransactionSuccessModal,
  TransactionCancelledModal,
  TransactionFailedModal,
  ContainedButton,
} from "components";
import { makeStyles } from "@material-ui/styles";
import { ApplicationModal } from "state/application/actions";

const useStyles = makeStyles(() => ({
  enter: {
    border: "1px solid green",
    backdropFilter: "blur(10px)",
    transform: "skewX(-10deg)",
    height: 50,
    width: 200,
    borderRadius: "20px 5px 20px 0px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all .3s ease",
    font: "15px sans-serif",
    fontWeight: 300,
    textShadow: "0 0 20px #fff",
    textTransform: "uppercase",
    animation: "breath2 2s .5s infinite alternate",
    cursor: "pointer",
    background: "transparent !important",
    borderColor: "#a8ecff",
    backgroundImage:
      "linear-gradient(to bottom, rgba(48, 138, 255, 0.5), rgba(29, 96, 240, 0.5))",
    boxShadow:
      "0 0 70px rgb(48 138 255 / 50%), 0 5px 20px rgb(48 138 255 / 50%), inset 0 1px #ffeca8, inset 0 -1px #ffeca8",
    color: "#a8ecff",

    "&:hover": {
      boxShadow:
        "0 0 70px rgb(48 138 255 / 80%), 0 5px 20px rgb(48 138 255 / 80%), inset 0 1px #a8ecff, inset 0 -1px #a8ecff",
    },

    "&::before": {
      content: "''",
      display: "block",
      width: "calc(100% - 22px)",
      height: "calc(50px - 8px)",
      animation: "breath 2s infinite alternate",
      left: 10,
      top: 3,
      position: "absolute",
      backgroundColor: "transparent",
      border: "1px solid #fff",
      borderRadius: "15px 3px 15px 3px",
      boxShadow: "inset 0 0 30px 0 #a8ecff",
    },
  },
  buttonWrapper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const StateUpdaters: React.FC = () => {
  return (
    <>
      <ApplicationUpdater />
      <UserUpdater />
      <TransactionsUpdater />
    </>
  );
};

const ThemeProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const darkMode = useIsDarkMode();
  let theme = darkMode ? darkTheme : lightTheme;

  if (location.pathname.replace("/", "") === "") {
    theme = darkTheme;
  }

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

const TopLevelModals: React.FC = () => {
  const transactionLoadingOpen = useModalOpen(
    ApplicationModal.TransactionLoading
  );
  const transactionSuccessOpen = useModalOpen(
    ApplicationModal.TransactionSuccess
  );
  const transactionCancelledOpen = useModalOpen(
    ApplicationModal.TransactionCancelled
  );
  const transactionFailedOpen = useModalOpen(
    ApplicationModal.TransactionFailed
  );
  const closeModals = useCloseModals();

  return (
    <>
      <TransactionLoadingModal
        open={transactionLoadingOpen}
        onClose={closeModals}
      />
      <TransactionSuccessModal
        open={transactionSuccessOpen}
        onClose={closeModals}
      />
      <TransactionCancelledModal
        open={transactionCancelledOpen}
        onClose={closeModals}
      />
      <TransactionFailedModal
        open={transactionFailedOpen}
        onClose={closeModals}
      />
    </>
  );
};

const Providers: React.FC = ({ children }) => {
  return (
    <BrowserRouter>
      <Suspense fallback={null}>
        <StateProvider store={store}>
          <StateUpdaters />

          <ThemeProvider>
            <CssBaseline />
            <TopLevelModals />
            {children}
          </ThemeProvider>
        </StateProvider>
      </Suspense>
    </BrowserRouter>
  );
};

const App: React.FC = () => {
  const classes = useStyles();
  const [isEnded, setIsEnded] = useState<boolean>(false);
  const [playing, setPlaying] = useState<boolean>(false);

  return (
    <Providers>
      <Switch>
        <Route exact path="/">
          <>
            {!isEnded && (
              <>
                <ReactPlayer
                  url="https://res.cloudinary.com/dgjjlsu7w/video/upload/v1662464592/training_openVid.mp4"
                  width="100vw"
                  height="100vh"
                  loop={false}
                  muted={false}
                  playing={playing}
                  playsinline={true}
                  onEnded={() => {
                    setIsEnded(true);
                  }}
                  style={{ overflow: "hidden" }}
                  config={{
                    file: {
                      attributes: {
                        preload: "auto",
                      },
                    },
                  }}
                />
                {!playing && (
                  <Box className={classes.buttonWrapper}>
                    <ContainedButton
                      className={classes.enter}
                      onClick={() => setPlaying(true)}
                    >
                      Enter
                    </ContainedButton>
                  </Box>
                )}
              </>
            )}
            {isEnded && (
              <PageWithSidebar>
                <Training />
              </PageWithSidebar>
            )}
          </>
        </Route>
      </Switch>
    </Providers>
  );
};

export default App;
