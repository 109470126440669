import { createAction } from "@reduxjs/toolkit";
import { ChainId } from "@uniswap/sdk";
import { API, Wallet } from "bnc-onboard/dist/src/interfaces";
import { ethers } from "ethers";

export enum ApplicationModal {
  TransactionLoading = "TransactionLoading",
  TransactionSuccess = "TransactionSuccess",
  TransactionFailed = "TransactionFailed",
  TransactionCancelled = "TransactionCancelled",
}
export interface SetWeb3Settings {
  onboard?: API;
  ethereum?: any;
  account?: string;
  signer?: ethers.Signer;
  chainId?: ChainId;
  web3?: ethers.providers.Web3Provider | null;
  wallet?: Wallet | null;
  isCompleted?: boolean;
}

export const setWeb3Settings = createAction<SetWeb3Settings>(
  "application/setWeb3Settings"
);

export const setActiveModal = createAction<ApplicationModal | null>(
  "application/setActiveModal"
);

export const setIsEntered = createAction<boolean>("application/setIsEntered");

export const setIsCompleted = createAction<boolean>(
  "application/setIsCompleted"
);
