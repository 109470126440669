import { useMemo } from "react";
import { Contract } from "@ethersproject/contracts";
import {
  getFighter1Contract,
  getWeaponContract,
  getMysteryContract,
  getGen2NFTContract,
  getGen2TrainerContract,
  getGCOINContract,
  getDeathRobotContract,
} from "utils/contractHelpers";
import { useWeb3 } from "state/application/hooks";

export const useFighter1Contract = (isSigner: boolean): Contract | null => {
  const { signer } = useWeb3();
  return useMemo(
    () => getFighter1Contract(isSigner ? signer : undefined),
    [signer, isSigner]
  );
};

export const useDeathRobotContract = (isSigner: boolean): Contract | null => {
  const { signer } = useWeb3();
  return useMemo(
    () => getDeathRobotContract(isSigner ? signer : undefined),
    [signer, isSigner]
  );
};

export const useWeaponContract = (isSigner: boolean): Contract | null => {
  const { signer } = useWeb3();
  return useMemo(
    () => getWeaponContract(isSigner ? signer : undefined),
    [signer, isSigner]
  );
};

export const useMysteryContract = (isSigner: boolean): Contract | null => {
  const { signer } = useWeb3();
  return useMemo(
    () => getMysteryContract(isSigner ? signer : undefined),
    [signer, isSigner]
  );
};

export const useGen2NFTContract = (isSigner: boolean): Contract | null => {
  const { signer } = useWeb3();
  return useMemo(
    () => getGen2NFTContract(isSigner ? signer : undefined),
    [signer, isSigner]
  );
};

export const useGen2TrainerContract = (isSigner: boolean): Contract | null => {
  const { signer } = useWeb3();
  return useMemo(
    () => getGen2TrainerContract(isSigner ? signer : undefined),
    [signer, isSigner]
  );
};

export const useGCOINContract = (isSigner: boolean): Contract | null => {
  const { signer } = useWeb3();
  return useMemo(
    () => getGCOINContract(isSigner ? signer : undefined),
    [signer, isSigner]
  );
};
