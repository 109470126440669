import { Contract } from "@ethersproject/contracts";
import { JsonRpcProvider } from "@ethersproject/providers";
import { ChainId } from "constants/tokens";
import {
  getFighter1Address,
  getWeaponAddress,
  getMysteryAddress,
  getGen2NFTAddress,
  getGen2TrainerAddress,
  getGCOINAddress,
  getDeathRobotAddress,
} from "./addressHelpers";
import Fighter1Abi from "constants/abi/fighter.json";
import WeaponAbi from "constants/abi/weapon.json";
import MysteryAbi from "constants/abi/mystery.json";
import GCOINAbi from "constants/abi/gcoin.json";
import Gen2TrainerAbi from "constants/abi/gen2trainer.json";
import Gen2NFTAbi from "constants/abi/gen2nft.json";
import DeathRobotAbi from "constants/abi/deathrobot.json";

const networks = {
  1: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  137: "https://polygon-mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  80001:
    "https://polygon-mumbai.g.alchemy.com/v2/SFF91lZNmnAw1I9GLIRcaq9HQPPfl6Jp",
};

export const getRpcUrl = (chainId: 1 | 137 | 80001) => {
  return networks[chainId];
};

export const getContract = (
  address: string,
  abi: any,
  signer?: any,
  chainId?: 1 | 137 | 80001
) => {
  const chainID = chainId ?? 137;
  const signerOrProvider = signer ?? new JsonRpcProvider(getRpcUrl(chainID));
  if (address) {
    return new Contract(address, abi, signerOrProvider);
  } else {
    return null;
  }
};

export const getFighter1Contract = (signer?: any) => {
  return getContract(
    getFighter1Address(ChainId.MAINNET),
    Fighter1Abi,
    signer,
    ChainId.MAINNET
  );
};

export const getDeathRobotContract = (signer?: any) => {
  return getContract(
    getDeathRobotAddress(ChainId.MAINNET),
    DeathRobotAbi,
    signer,
    ChainId.MAINNET
  );
};

export const getWeaponContract = (signer?: any) => {
  return getContract(getWeaponAddress(ChainId.MATIC), WeaponAbi, signer);
};

export const getMysteryContract = (signer?: any) => {
  return getContract(getMysteryAddress(ChainId.MATIC), MysteryAbi, signer);
};
export const getGen2NFTContract = (signer?: any) => {
  return getContract(getGen2NFTAddress(ChainId.MATIC), Gen2NFTAbi, signer);
};
export const getGen2TrainerContract = (signer?: any) => {
  return getContract(
    getGen2TrainerAddress(ChainId.MATIC),
    Gen2TrainerAbi,
    signer
  );
};
export const getGCOINContract = (signer?: any) => {
  return getContract(getGCOINAddress(ChainId.MATIC), GCOINAbi, signer);
};
