export enum ChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GÖRLI = 5,
  KOVAN = 42,
  MATIC = 137,
  MATIC_TESTNET = 80001,
  FANTOM = 250,
  FANTOM_TESTNET = 4002,
  XDAI = 100,
  BSC = 56,
  BSC_TESTNET = 97,
  ARBITRUM = 79377087078960,
  MOONBASE = 1287,
  AVALANCHE = 43114,
  FUJI = 43113,
  HECO = 128,
  HECO_TESTNET = 256,
  HARMONY = 1666600000,
  HARMONY_TESTNET = 1666700000,
  OKEX = 66,
  OKEX_TESTNET = 65,
}

export const ContractAddresses: any = {
  [ChainId.MAINNET]: {
    Fighter1: "0x3702f4C46785BbD947d59A2516ac1ea30F2BAbF2",
    DeathRobot: "0x07C3249625D2F1e825325aa739c5d8e69b96f3Ac",
  },
  [ChainId.MATIC]: {
    Weapon: "0xCbc964dd716F07b4965B4526E30541a66F414ccF",
    Gen2NFT: "0xC93284A7912201276B4b429F0045CcA32dB6682d",
    Gen2Trainer: "0xD3d36A38b815d7ED4633b28bcb5FC2C84AD66DF5",
    Mystery: "0xFd24D200C6715f3C0a2DdF8a8b128952eFed7724",
    GCOIN: "0x071AC29d569a47EbfFB9e57517F855Cb577DCc4C",
  },
  [ChainId.MATIC_TESTNET]: {
    Weapon: "0xF352a0A78402a95241D80CaEee4C9513D869bf8E",
    Gen2NFT: "0x6B0bBC603bCF8d3e45bc670Ac790d86d77197ebE",
    Gen2Trainer: "0x2bB84e4dB53B46B1c7f4F8a64217e62cc41351B0",
    Mystery: "0xd0AaB92f2915d8824e21A549016C76b4e6b2fFc2",
    GCOIN: "0x6055498864f8F04F3D11D7bE3fa8E739373C0A5C",
  },
};
