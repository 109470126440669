const wallets = () => {
  return [
    { walletName: 'coinbase', preferred: true },
    { walletName: "metamask", preferred: true },
    {
      walletName: "walletConnect",
      preferred: true,
      rpc: {
        137: "https://matic-mainnet.chainstacklabs.com",
      },
    },
    {
      walletName: "walletLink",
      rpcUrl: "https://mainnet.infura.io/v3/a6699e4ad89f4c4187e807f0709a360c",
      appName: "GENESIS",
      preferred: true,
    },
  ];
};

export default wallets;
