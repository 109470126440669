import axios from "axios";

const call = (url: string, method: string, data = {}) => {
  let header = {
    "Content-Type": "application/json",
  };

  const options: any = {
    url,
    method,
    headers: header,
    crossDomain: true,
  };

  return axios
    .request(options)
    .then((res) => res.data)
    .catch((error) => {
      console.log("Error:", error);
      return error.response?.data || error.response;
    });
};

export default call;
