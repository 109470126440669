import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Box, Button, Container, Divider, Grid, IconButton, Typography, useMediaQuery
} from "@material-ui/core";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import cx from "classnames";
import { ContainedButton } from "components";
import Hamburger from "hamburger-react";
import { setIsEntered } from "state/application/actions";

import { AccountButtons, Footer, Sidebar } from "components";
import { useWeb3 } from "state/application/hooks";
import { useDarkModeManager } from "state/user/hooks";
import { switchPolygonNetwork } from "utils";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  page: {
    backgroundImage: `url("https://res.cloudinary.com/dgjjlsu7w/image/upload/v1662472095/Gen2-training-background.jpg")`,
    backgroundPosition: "right center",
    backgroundSize: "cover",
    position: "relative",
    minHeight: "100vh",
    display: "flex",
    overflowX: "hidden",
    flexDirection: "column",

    [breakpoints.down("md")]: {
      marginLeft: 0,
      width: "100vw",
    },
    [breakpoints.up("md")]: {
      marginLeft: (props: any) => (props.isWalletConnected && !props.isSidebarHidden ? 127 : 0),
      width: (props: any) =>
        props.isWalletConnected && !props.isSidebarHidden ? "calc(100vw - 127px)" : "100vw",
    },
  },
  pageMobile: {
    width: "100vw",
    marginLeft: 0,
    height: "100vh",
  },
  border: {
    borderBottom: (props: any) =>
      (props.darkMode || !props.mobileSidebarHidden) &&
      `1px solid ${palette.divider}`,
    boxShadow: (props: any) =>
      props.darkMode
        ? "none"
        : !props.mobileSidebarHidden
        ? "0px 1.73333px 25.1333px rgba(0, 0, 0, 0.0103512)"
        : "0px 2px 5px rgba(0, 0, 0, 0.0746353)",
  },
  transitionItem: {
    opacity: 0,
    transform: "scale(0)",
    transformOrigin: "center 0",
    position: "absolute",
    transition:
      "opacity 354ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 236ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  transitionOpen: {
    opacity: 1,
    transform: "scale(1)",
  },
  header: {
    padding: 24,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: (props: any) =>
      props.isWalletConnected ? "transparent" : palette.background.default,
  },
  addPolygon: {
    maxWidth: 600,
    width: "100%",
    padding: 38,
    borderRadius: 50,
    opacity: 0.9,
    background: palette.background.default,
    margin: "auto",
    fontFamily: 'Ribuk!important',

    "& > p": {
      fontSize: "24px",
      textAlign: "center",
      marginBottom: 24,
      fontWeight: 800,
      fontFamily: 'Ribuk!important',
    },

    "& button": {
      background: "#00B9BA!important",
      height: 52,
      width: "fit-content",
      margin: "0 auto",

      "& p": {
        fontSize: 16,
        fontWeight: 400,
      },
    },
  },
  supportedNetwork: {
    background: palette.common.white,
    width: "calc(100% + 48px)",
    height: 46,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "2rem",

    "& > span": {
      fontSize: 24,
      marginRight: 50,
      color: palette.common.black,
      fontFamily: 'Ribuk',
      fontWeight: 800,
    },

    "& span": {
      whiteSpace: "nowrap",
    },

    "& > button": {
      width: "fit-content",
      background: "#00B9BA!important",
      borderRadius: 30,

      "&:hover": {
        background: "#00B9BA!important",
        opacity: 0.8,
      },
    },
  },
}));

export interface PageWithSidebarProps {
  children: any;
}

const PageWithSidebar: React.FC<PageWithSidebarProps> = ({ children }) => {
  const [mobileSidebarHidden, setMobileSidebarHidden] = useState(true);
  const theme = useTheme();
  let url = new URL(window.location.href);
  let params = new URLSearchParams(url.search);
  const isSidebarHidden = params.get('isSidebarHidden');
  const { account, wallet, chainId } = useWeb3();
  const dispatch = useDispatch();

  const isWalletConnected = !!wallet && !!wallet.provider && !!account;

  const { palette } = theme;
  const [darkMode] = useDarkModeManager();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles({
    darkMode,
    mobileSidebarHidden,
    isWalletConnected,
    isSidebarHidden
  });

  const hideMobileMenu = () => {
    setMobileSidebarHidden(true);
  };

  useEffect(() => {
    dispatch(setIsEntered(true));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!mobile && !mobileSidebarHidden) {
      setMobileSidebarHidden(true);
    }
  }, [mobile, mobileSidebarHidden]);

  return (
    <Box>
      <Grid container>
        {!mobile && isWalletConnected && !isSidebarHidden &&(
          <Box position="fixed" left={0} width={127}>
            <Sidebar />
          </Box>
        )}

        <Box className={cx(classes.page, mobile && classes.pageMobile)}>
          <Box
            position="fixed"
            width={
              mobile ? 1 : isWalletConnected && !isSidebarHidden ? "calc(100vw - 127px)" : "100vw"
            }
            zIndex={10}
            bgcolor={!mobile ? "transparent" : palette.background.paper}
            className={cx(mobile && classes.border, !mobile && classes.header)}
            height={mobile ? "60px" : "auto"}
          >
            <Box width="100%" display="flex" justifyContent="center">
              <Box
                display="flex"
                width="100%"
                maxWidth="1280px"
                justifyContent={!mobile ? "flex-end" : "space-between"}
                alignItems="center"
              >
                {mobile && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Grid style={{ height: "48px", marginRight: "6px" }}>
                      <IconButton
                        style={{ height: "48px", padding: 0 }}
                        onClick={() =>
                          setMobileSidebarHidden(!mobileSidebarHidden)
                        }
                      >
                        <Hamburger
                          size={20}
                          color={theme.palette.text.secondary}
                          toggled={!mobileSidebarHidden}
                          toggle={setMobileSidebarHidden}
                        />
                      </IconButton>
                    </Grid>
                  </Box>
                )}
                {!mobile && (
                  <Box width="100%" display="flex" justifySelf="flex-end">
                    <AccountButtons />
                  </Box>
                )}
              </Box>
            </Box>
            {isWalletConnected && chainId !== 137 && chainId !== 80001 && (
              <Box className={classes.supportedNetwork}>
                <Typography component="span">
                  You need to connect to the supported network
                </Typography>
                <Button
                  onClick={() => {
                    switchPolygonNetwork();
                  }}
                >
                  Switch Network
                </Button>
              </Box>
            )}
          </Box>

          {mobile && (
            <Box
              className={cx(
                classes.transitionItem,
                !mobileSidebarHidden && classes.transitionOpen
              )}
              width={
                mobile ? 1 : isWalletConnected  && !isSidebarHidden? "calc(100vw - 127px)" : "100vw"
              }
              position="relative"
              mt="60px"
              mb={mobile ? 0 : 7}
              height={1}
              maxHeight="calc(100vh - 100px)"
              overflow="auto"
              style={{ backgroundColor: palette.background.paper }}
            >
              <Box p={!mobile ? 1 : 0}>
                <AccountButtons onHide={hideMobileMenu} mobile />
              </Box>
              <Divider />
              <Box p={1} pl={1.25}>
                <Sidebar mobile onHide={hideMobileMenu} />
              </Box>

              <Box
                borderBottom={`1px solid ${palette.divider}`}
                boxShadow={
                  darkMode ? "" : "0px 2px 5px rgba(0, 0, 0, 0.0746353)"
                }
              />
            </Box>
          )}

          {mobileSidebarHidden && (
            <>
              <Box
                px={0}
                width={
                  mobile
                    ? "100vw"
                    : isWalletConnected && !isSidebarHidden
                    ? "calc(100vw - 127px)"
                    : "100vw"
                }
                height="100vh"
                display="flex"
                flexDirection="column"
              >
                {isWalletConnected && (
                  <Container style={!mobile ? {} : { padding: "0 20px" }}>
                    {children}
                  </Container>
                )}
                {!isWalletConnected && (
                  <Box className={classes.addPolygon}>
                    <Typography>
                      In order to use the forging system, you will have to be on
                      the Polygon network
                    </Typography>
                    <ContainedButton
                      id="contained-button"
                      label="Add Polygon Network"
                      size="large"
                      fullWidth
                      onClick={() => {
                        switchPolygonNetwork();
                      }}
                    />
                  </Box>
                )}
                {mobile && (
                  <Box width="100%" zIndex={14}>
                    <Footer />
                  </Box>
                )}
              </Box>
              {!mobile && (
                <Box
                  position="fixed"
                  width={isWalletConnected && !isSidebarHidden ? "calc(100vw - 127px)" : "100vw"}
                  bottom={0}
                  zIndex={14}
                  bgcolor={palette.background.default}
                >
                  <Footer />
                </Box>
              )}
            </>
          )}

          {mobile && !mobileSidebarHidden && (
            <Box position="fixed" width="100%" bottom={0} zIndex={14}>
              <Footer />
            </Box>
          )}
        </Box>
      </Grid>
    </Box>
  );
};

export default PageWithSidebar;
