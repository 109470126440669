import Notify, { API as NotifyAPI } from "bnc-notify";
import { API as OnboardAPI, Wallet } from "bnc-onboard/dist/src/interfaces";
import { createReducer } from "@reduxjs/toolkit";
import { ethers } from "ethers";
import { Provider } from "ethers-multicall";
import { ChainId } from "@uniswap/sdk";

import {
  ApplicationModal,
  setWeb3Settings,
  setIsEntered,
  setActiveModal,
  setIsCompleted,
} from "./actions";

export interface ApplicationState {
  onboard?: OnboardAPI;
  notify: NotifyAPI;
  chainId?: ChainId | 137 | 80001;
  ethereum: any;
  account: string;
  signer?: ethers.Signer;
  web3?: ethers.providers.Web3Provider | null;
  multicallProvider?: Provider | null;
  wallet?: Wallet | null;
  activeModal?: ApplicationModal | null;
  isEntered: boolean;
  isCompleted?: boolean;
}

export const initialState: ApplicationState = {
  onboard: undefined,
  isEntered: false,
  notify: Notify({
    dappId: process.env.REACT_APP_BLOCKNATIVE_KEY,
    networkId: ChainId.MAINNET,
  }),
  chainId: undefined,
  ethereum: (window as any).ethereum,
  account: "",
  signer: undefined,
  web3: undefined,
  wallet: undefined,
  activeModal: undefined,
  isCompleted: false,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setWeb3Settings, (state, { payload }) => {
      const { onboard, ethereum, account, signer, web3, wallet, chainId } =
        payload;

      state.onboard = onboard || state.onboard;
      state.ethereum = ethereum || state.ethereum;
      state.account = account ? account.toLowerCase() : state.account;
      state.signer = signer || state.signer;
      state.web3 = web3 !== undefined ? web3 : state.web3;
      state.wallet = wallet !== undefined ? wallet : state.wallet;
      state.chainId = chainId ?? state.chainId;

      if (state.web3) {
        state.multicallProvider = new Provider(state.web3, state.chainId);
      }

      if (chainId) {
        state.notify.config({ networkId: Number(chainId) });
      }

      if (state.onboard) {
        state.onboard.config({ networkId: Number(chainId) });
      }
    })
    .addCase(setActiveModal, (state, { payload }) => {
      state.activeModal = payload;
    })
    .addCase(setIsEntered, (state, { payload }) => {
      state.isEntered = payload;
    })
    .addCase(setIsCompleted, (state, { payload }) => {
      state.isCompleted = payload;
    })
);
